.fade-enter {
  opacity: 0.01;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.fade-leave {
  opacity: 1;
}

.fade-leave-active {
  opacity: 0.01;
  transition: opacity 500ms ease-in;
}

.zenek {
  opacity: 0.01;
}