@import '~@blueprintjs/core/lib/scss/variables';

.ContentWithSidebar {
  $sidebar-width: 300px;

  .ContentToolbar {
    $toolbar-height: 25px;
    $toolbar-padding-side: 15px;
    $toolbar-padding-top-bottom: 10px;
    $toolbar-height-with-padding: $toolbar-height + 2*$toolbar-padding-top-bottom;

    position: fixed;
    top: 0;
    right: 0;
    padding:
            $pt-navbar-height+$toolbar-padding-top-bottom
            $sidebar-width+$toolbar-padding-side
            $toolbar-padding-top-bottom
            $toolbar-padding-side
    ;

    z-index: 1;

    box-shadow: $pt-dark-elevation-shadow-1;


    width: 100%;
    height: $pt-navbar-height + $toolbar-height-with-padding;

    .bp3-navbar-group {
      height: $toolbar-height;
    }

    &.bp3-dark {
      background-color: $gray1;
    }

    & + .Content {
      margin-top: $toolbar-height-with-padding;
    }
  }

  .Content {
    padding-top: 20px;
    margin-right: $sidebar-width;

    display: flex;
    justify-content: center;

    & > div {
      width: 100%;
      max-width: 600px;
    }
  }

  .Sidebar {
    position: fixed;
    right: 0;
    top: 0;
    padding: $pt-navbar-height+15px 15px 15px;

    z-index: 2;

    box-shadow: $pt-dark-elevation-shadow-1;


    background-color: $gray1;
    height: 100%;
    width: $sidebar-width;

    overflow-y: auto;

    & > * {
      margin-bottom: 10px;
    }
  }
}