.bp3-portal {
  .bp3-overlay-content {
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;

    pointer-events: none;

    & > * {
      pointer-events: all;
    }
  }
}