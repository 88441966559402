@import '~@blueprintjs/core/lib/scss/variables';

.Admin {
  .bp3-navbar-heading {
    height: 100%;

    a {
      float: left;
      font-size: 1.25rem;
      height: 100%;

      color: white;

      &.title {
        line-height: $pt-navbar-height;

        &:hover {
          text-decoration: none;
          color: $light-gray1;
        }
      }


      img, span {
        height: 100%;
      }

      img {
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
        margin-right: 1rem;
      }
    }
  }
}