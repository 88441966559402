@import "~@blueprintjs/core/lib/scss/variables";

.ExerciseQuestion.bp3-card {
  padding: 0;

  & > * {
    padding: 0 20px;

    &:first-child {
      padding-top: 20px;
    }

    &:last-child {
      padding-bottom: 20px;
    }
  }

  .question-header {
    background-color: $blue1;
    padding-bottom: 20px;

    &.has-no-content {
      padding-top: 5px;
      padding-bottom: 5px;

      .card-header {
        display: flex;
        width: 100%;
        justify-content: space-between;
      }
    }

    .card-header {
      color: $white;
      position: relative;
      margin: 0;

      .card-header-content {
        .bp3-editable-text-content, .bp3-editable-text-input {
          max-width: 100%;
        }

        &.bp3-editable-text-editing {
          color: $black;
        }

        &.bp3-editable-text-placeholder .bp3-editable-text-content {
          color: $gray5;
        }
      }

      .delete-question-button {
        position: absolute;
        right: 0;
      }
    }
  }

  .question-content {
    padding-top: 20px;

    button.add-answer {
      width: 100%;
      padding: 20px 10px;
    }
  }

  .answer {
    margin-bottom: 10px;
  }
}