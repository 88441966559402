.Input {
  .bp3-label {
    font-weight: 700;
  }

  &.small {
    width: 45%;
    display: inline-flex;
    margin-right: 4%;
  }
}

.Input, .bp3-form-group.Input {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}