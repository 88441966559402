@import "~@blueprintjs/core/lib/scss/variables";

.ErrorOverlay {
  .bp3-card {
    display: flex;
    align-items: center;

    background-color: $red1;


    .bp3-non-ideal-state {
      color: $white;

      .bp3-non-ideal-state-visual {
        .bp3-icon {
          color: $red5;
        }
      }

      .bp3-heading {
        color: $white;
      }
    }
  }
}