.Report {
  .Content {
    section {
      margin-bottom: 32px;
    }

    .bp3-html-table {
      margin: auto;

      td {
        text-align: center;
      }

      td:first-child {
        text-align: right;
      }
    }

    .bp3-card > *:last-child{
      margin-bottom: 0;
    }

    .question {
      .answer.correct {
        font-weight: 700;
      }
    }
  }
}