@import "../../../node_modules/@blueprintjs/core/lib/scss/variables";


.Login {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-position: center;
  background-size: cover;
  background-color: rgba(0,0,0, 0.5);

  background-blend-mode: overlay;

  .bp3-overlay.bp3-overlay-inline {
    display: flex;
    justify-content: center;

    .login-overlay {
      padding: 2rem;
      margin-top: 5rem;

      font-size: 2rem;
      font-weight: 700;
    }
  }

  .container {
    padding-top: 3rem;

    .form-container {
      background-color: transparentize($light-gray2, 0.2);

      form {
        margin-top: 2rem;

        button {
          margin-top: 1rem;
        }
      }
    }
  }
}