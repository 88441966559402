@import '~@blueprintjs/core/lib/scss/variables';

.ProjectCard.bp3-card {
  background-color: $light-gray5;

  &.selected {
    background-color: $light-gray1;

  }

  .drag-handle {
    padding: 8px 8px 7px 0;
    margin-left: -6px;
  }

  .title {
    display: flex;

    justify-content: space-between;
    align-items: center;

    margin-bottom: 8px;

    .name {
      margin: 0 16px 0 0;
      flex-grow: 1;

      .slug {
        font-size: 0.8em;
        color: $gray2;
      }
    }
  }

  .description {
    margin-bottom: 0;
  }
}