@import "~@blueprintjs/core/lib/scss/variables";

$answer-padding: 10px;

.ExerciseAnswer.bp3-card {
  padding: 0;

  & > * {
    padding: 0 $answer-padding;

    &:first-child {
      padding-top: $answer-padding;
    }

    &:last-child {
      padding-bottom: $answer-padding;
    }
  }

  .answer-header {
    background-color: $light-gray1;
    padding-bottom: $answer-padding;
  }

  .AnswerParameters {
    padding-top: $answer-padding;
  }
}