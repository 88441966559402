@import 'colors';
@import url('https://fonts.googleapis.com/css?family=Lato:400,700&subset=latin-ext');
@import '~@blueprintjs/core/lib/css/blueprint.css';
@import '~@blueprintjs/icons/lib/css/blueprint-icons.css';
@import '~@blueprintjs/select/lib/css/blueprint-select.css';

@import '~@blueprintjs/core/lib/scss/variables';

body {
  font-family: Lato, sans-serif;

  padding-top: $pt-navbar-height;
}

#root {
  a {
    transition: all 200ms ease-in-out;
  }

  .bp3-dark a, .bp3-dark a:hover {
    color: inherit;
  }

  .bp3-dark .bp3-button.bp3-minimal:disabled,
  .bp3-dark .bp3-button.bp3-minimal:disabled:hover,
  .bp3-dark .bp3-button.bp3-minimal.bp3-disabled,
  .bp3-dark .bp3-button.bp3-minimal.bp3-disabled:hover {
    color: $pt-dark-text-color-disabled;
  }

  .bp3-html-table {
    th, td {
      vertical-align: middle;
    }

    th {
      text-align: center;

      &.name {
        width: 100%;
      }
    }

    tr.dragged {
      width: 100%;

      th, td {
        border: none;
      }

      td.actions {
        display: none;
      }
    }
  }

  .drag-handle {
    cursor: grab;
  }

  .dragged .drag-handle {
    cursor: grabbing;
  }
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.table {
  th, td {
    vertical-align: middle;
  }

  th {
    text-align: center;

    &.name {
      width: 100%;
    }
  }

  td {
    &.actions {
      text-align: right;
      white-space: nowrap;

      * {
        margin-right: 0.5rem;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

.clickable {
  cursor: pointer;
}

input.invisible-edit, textarea.invisible-edit {
  border-color: #00000000;
  background-color: initial;

  &:hover, &:focus {
    border:1px solid #ced4da;
  }

  &:focus {
    background-color: #fff;
  }
}

.bp3-button-group.modal-buttons {
  display: flex;
  width: 100%;

  .bp3-button {
    flex: 1;
  }
}

.bp3-input {
  font-family: -apple-system, "BlinkMacSystemFont", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Open Sans", "Helvetica Neue", "Icons16", sans-serif;
}

.bp3-popover-content {
  padding: 10px;

  & > *:last-child {
    margin-bottom: 0;
  }
}