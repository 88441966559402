@import '../../scss/colors';

.Exercise {
  position: relative;

  .card-header {
    display: flex;
    margin-bottom: 0;
    align-items: center;

    .card-header-drag-icon {
      flex-shrink: 0;
      flex-grow: 0;
      margin-right: 10px;
    }

    .card-header-content {
      width: 100%;
    }

    .card-header-button {
      flex-shrink: 0;
      flex-grow: 0;

      height: 30px;
      margin-left: 10px;
    }
  }

  #exercise-name {
    margin: 0.5rem 0 1.5rem;
  }


  .exercise-title {
    height: calc(4rem + 2px);
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }

  .card {
    margin-bottom: 0.5rem;

    .card-header {
      padding-left: 0.75rem;
      padding-right: 0.75rem;

      input {
        padding: 1.5rem 0.5rem;
      }
    }
  }

  .question {
    .question-header {
      background-color: $color_main;

      .drag-bar {
        text-align: center;
      }

      textarea {
        font-size: 1.5rem;
        color: #fff;

        &:focus {
          color: #495057;
        }
      }
    }

    .answer {
      color: #495057;

      label {
        text-align: right;
      }

      .form-group:last-child {
        margin-bottom: 0;
      }

      .toggleable {
        user-select: none;
        color: red;
        transition: color 300ms linear;

        &.correct {
          color: green;
        }
      }

      .card-body:empty {
        padding: 0;
      }
    }
  }
}