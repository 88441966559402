.NumericInput {
  width: 100%;

  .bp3-input-group {
    width: 100%;
    margin-left: -30px;
    padding-left: 30px;
  }

  &.small {
    display: inline-block;
    width: 49%;
  }
}