.BooleanInput {
  .bp3-switch {
    margin-bottom: 0;

    &:last-child {
      margin-top: 0;
    }
  }

  &.small {
    width: 49%;
    display: inline-flex;
  }

  &.bp3-form-group.bp3-inline {
    label.bp3-label {
      line-height: inherit;
    }
  }
}