@import 'colors';

$navbar-height: 60px;
// TODO Ustawić np. na 200px, jak już będziemy używać sidebara
$sidebar-width: 0px;


//body {
//  padding-top: 0 !important;
//}
//
//.bp3-navbar.bp3-fixed-top.bp3-dark {
//  display: none;
//}
//
//.ContentToolbar, .Sidebar {
//  display: none;
//}
//
//.recharts-label-list tspan {
//  display: none;
//}
//
//.Content {
//  padding-top: 0 !important;
//  margin-right: 0 !important;
//}

.App {
  #top-bar {
    position: fixed;
    height: $navbar-height;
    width: 100%;
    top: 0;

    background-color: $color_gray_lightest;

    z-index: 10;

    .navbar-brand {
      height: 100%;
      color: $color_main;
      transition: all 300ms;

      &:hover {
        color: darken($color_main, 15%);
      }

      img {
        height: 100%;
      }
    }
  }

  #sidebar-container {
    position: fixed;
    top: $navbar-height;
    left: 0;
    width: $sidebar-width;

    padding-bottom: $navbar-height;
    height: 100%;

    #sidebar {
      width: 100%;
      height: 100%;

      background-color: $color_gray_dark;

      .nav-item {
        transition: all 300ms;

        &:hover {
          background-color: $color_gray;
        }

        .nav-link {
          color: #FFFFFF;
        }
      }
    }
  }

  #main {
    margin-top: $navbar-height;
    margin-left: $sidebar-width;

    padding: 1rem 0;
  }

  #action-bar {
    height: $navbar-height;
    padding: 0 1rem 0 0;

    position: fixed;
    z-index: 15;
    top: 0;
    right: 0;

    display: flex;
    align-items: center;
    flex-direction: row;

    button {
      margin-left: 0.5rem;

      svg {
        margin-right: 0.5rem;
      }
    }
  }
}