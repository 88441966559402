@import "~@blueprintjs/core/lib/scss/variables";

.AddExerciseModal {
  .bp3-heading {
    margin-bottom: 2rem;
  }

  .exercise-select {
    .bp3-popover-target {
      margin-bottom: 1rem;
    }

    .bp3-popover-target, .bp3-button {
      width: 100%;
    }
  }
}

.bp3-popover-content {
  .select-exercise-choice {
    cursor: pointer;

    background-color: $light-gray3;
    padding: 8px;
    margin-bottom: 2px;

    h4, p {
      margin: 0;
    }

    &:hover {
      background-color: $light-gray1;
    }
  }
}

@media (min-width: 768px) {

}